import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/home.css";

import heroHeatedImage from "../images/collection with logo rz.png";
import chillingChiliImage from "../images/getting-heated-rz.png";
import burningBlueChili from "../images/burning-blue.png";
import robinHoodChiliImage from "../images/robin hood rz.png";
import goldenPepperEditionImage from "../images/golden-pepper-logo.png";
import printAtHomeEditionImage from "../images/print-at-home.png";
import lowInkEditionImage from "../images/low-ink.png";
import summary from "../images/game-summary.png";
import giftBox from "../images/clean-gift-box-sm.png";
import giftBoxFlipped from "../images/clean-gift-box-sm-flipped.png";

import greenChili from "../images/green-chili-sm.png";
import magentaChili from "../images/magenta-chili-sm.png";
import yellowChili from "../images/yellow-chili-sm.png";
import blueChili from "../images/blue-chili-sm.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const shopWebsite: string = "https://heatedgame.myshopify.com"
const printAtHomeUrl: string = "https://heatedgame.com/print-at-home"
const lowInkUrl: string = "https://heatedgame.com/low-ink"

export default class Home extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (<div className="App">
      <Navbar />
      <div className='hero-banner-wrapper'>
        <div className='hero-banner'>
          <img className="hero-banner-image"
            src={heroHeatedImage} alt="Heated! The Spiciest Card Game" title="HEATED!" />
          <a className='hero-banner-cta' href={shopWebsite} rel="noopener noreferrer" target="_blank">Shop Now!</a>
        </div>
      </div>
      <div className='content-row-wrapper content-row-wrapper-blue'>
        <div className='content-row'>
          <div className='content-row-header-container'>
            <img className='content-row-header-image' src={chillingChiliImage}
              height={75} width={81} alt="Chill chili" />
            <h2 className='content-row-header'>WELCOME TO HEATED</h2>
          </div>

          <p className='content-row-subheader'>the fast-paced, family-friendly, color-blind accessible frenzy of a&nbsp;game!</p>
          <div className='what-is-heated'>
            <div className='what-is-heated-left'>
              <p className='what-is-heated-messaging'>HEATED is able to be played by all <span className='highlighted-text'>300 million+</span> color-deficient individuals worldwide without sacrificing charm or design. It's <span className='highlighted-text'>easy to learn</span> and <span className='highlighted-text'>fun to play!</span> Simply get rid of all the cards in your hand first to&nbsp;<span className='highlighted-text'>WIN!</span></p>

            </div>
            <div className='what-is-heated-right'>
              <img src={robinHoodChiliImage} alt="Robin hood chili" height={125} width={90} />
              <em className='what-is-heated-quote'>“HEATED is like Uno, but without<br />all the boring parts.”</em>

            </div>

          </div>
          <div className='heated-writeup'>
            <p className='heated-writeup-paragraph'>
              HEATED is a card-shedding variant of Crazy-Eights (made popular by UNO). Specifically, HEATED is inspired by the unofficial rules of SPICY UNO, but with many more SPECIAL cards to add to the fun!</p>
            <p className='heated-writeup-paragraph'>You need to match the last color or action card played and has the same core game mechanics as Crazy-8s.</p>
            <p className='heated-writeup-paragraph'>Don't want to wait for your turn? If you have the EXACT SAME card (that means color and image), you can jump ahead and skip your competition! This keeps you constantly engaged, even when it isn't your turn.</p>
            <p className='heated-writeup-paragraph'> It's a fantastic, fast paced game for fast paced people! Order now by <a className='highlighted-text' target="_blank" rel='noopener noreferrer' href={shopWebsite}>clicking here!</a> Or read the <a className='how-to-play-link' href={require('../pdf/manual.pdf')}>full set of&nbsp;rules.</a></p>
            <p className='heated-writeup-paragraph'></p>
            <p className='heated-writeup-paragraph'></p>

          </div>

        </div>
      </div>
      <div className='content-row-wrapper content-row-wrapper-white'>
        <div className='content-row'>

          <div className='print-at-home-edition-container'>

            <div className='print-at-home-edition-header-container'>
              <img className='print-at-home-edition-header-image-left' src={giftBoxFlipped} alt="Gift Box" />
              <h2 className='print-at-home-edition-header'>PRINT-AT-HOME EDITIONS</h2>
              <img className='print-at-home-edition-header-image-right' src={giftBox} alt="Gift Box" />
            </div>

            <div className='print-at-home-edition-messaging-container'>
              <p className='print-at-home-edition-messaging'>HEATED is available to <a href='https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode' target="_blank" rel="noopener noreferrer">download for free,</a> right here on our website. The Print-At-Home PDFs come with instructions for printing as well as how to put all the cards together. All you need are some scissors and a printer (though, we also recommend laminating).</p>

              <p className='print-at-home-edition-messaging'>Whether you want to try out the game for free, or simply get a look at every card in the deck, these PDFs are perfect for you. We supply both the full game as well as a Low-Ink Edition for those that want to save on Ink, but still get the full experience of the game. If you enjoy, please consider purchasing an official&nbsp;copy!</p>
            </div>
            <div className='print-at-home-editions'>
              <div className='print-at-home-edition'>

                <LazyLoadImage className='golden-pepper-edition-image' src={printAtHomeEditionImage} width={312} height={175} alt="Print-At-Home Edition" />
                <a className='print-at-home-cta' href={printAtHomeUrl} target="_blank" rel="noopener noreferrer">Download</a>
              </div>
              <div className='low-ink-edition'>

                <LazyLoadImage className='golden-pepper-edition-image' src={lowInkEditionImage} width={312} height={175} alt="Low-Ink Print-At-Home Edition" />
                <a className='print-at-home-cta' href={lowInkUrl} target="_blank" rel="noopener noreferrer">Download</a>
              </div>

            </div>

          </div>

        </div>
      </div>
      <div className='content-row-wrapper content-row-wrapper-black'>
        <div className='content-row'>

          <div className='golden-pepper-edition-container'>
            <div className='golden-pepper-edition-left'>

              <p>
                Order now to get access to the limited <span className='golden-pepper-edition-text'>GOLDEN&nbsp;PEPPER&nbsp;EDITION</span> with gold-edged cards and magnetic box!
              </p>
              <div className='chili-images'>
                <LazyLoadImage src={blueChili} className="chili-mascot-inline" alt="Blue Mascot" width={52} height={100} />
                <LazyLoadImage src={greenChili} className="chili-mascot-inline" alt="Green Mascot" width={52} height={100} />
                <LazyLoadImage src={magentaChili} className="chili-mascot-inline" alt="Magenta Mascot" width={52} height={100} />
                <LazyLoadImage src={yellowChili} className="chili-mascot-inline" alt="Green Mascot" width={52} height={100} />
              </div>

            </div>
            <div className='golden-pepper-edition-right'>
              <LazyLoadImage className='golden-pepper-edition-image' src={goldenPepperEditionImage} width={312} height={175} alt="Golden Pepper Edition" />
              <a className='golden-pepper-cta' href={shopWebsite} target="_blank" rel="noopener noreferrer">Order Now!</a>
            </div>

          </div>

        </div>
      </div>
      <div className='content-row-wrapper content-row-wrapper-blue'>
        <div className='content-row'>

          <div className='how-to-play-container'>
            <h3 className='how-to-play-header'>Game Details <LazyLoadImage className='how-to-play-burning-img' alt="Burning Chili" src={burningBlueChili} /></h3>
            <p className='how-to-play-text'>For the complete list of rules on how to play HEATED <a className='how-to-play-link' href={require('../pdf/manual.pdf')}>click&nbsp;here!</a></p>
            <LazyLoadImage className='how-to-play-image' src={summary} alt="Summary" height={200} />

          </div>


        </div>
      </div>
      <Footer />
    </div>)
  }
}
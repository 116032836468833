import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/not-found.css";




export default class DeckBoxRedirect extends React.Component<{}, {}> {

  componentDidMount() {
    window.location.href = "https://www.amazon.com/dp/B087C6WC3H?psc=1&ref=ppx_yo2_dt_b_product_details&_encoding=UTF8&tag=texttopic-20&linkCode=ur2&linkId=0817f00be35f0a9fc2ad142b68b8a578&camp=1789&creative=9325";
  }

  render(): React.ReactNode {
    return (<div className="not-found-page">
      <Navbar />
      <div className='not-found-contents'>
        <p className='not-found-header'>Redirecting to recommended deck box...</p>
        <br />
        <a className='not-found-link' href="https://www.amazon.com/dp/B087C6WC3H?psc=1&ref=ppx_yo2_dt_b_product_details&_encoding=UTF8&tag=texttopic-20&linkCode=ur2&linkId=0817f00be35f0a9fc2ad142b68b8a578&camp=1789&creative=9325">Click here if you are not automatically redirected.</a>
      </div>
      <Footer />
    </div>)
  }
}
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/not-found.css";




export default class LaminateRedirect extends React.Component<{}, {}> {

  componentDidMount() {
    window.location.href = "https://www.amazon.com/dp/B00007E7D2?ref=ppx_yo2_dt_b_product_details&th=1&_encoding=UTF8&tag=texttopic-20&linkCode=ur2&linkId=1c03fcc8d2d4154a6ba0aaf858129888&camp=1789&creative=9325";

  }

  render(): React.ReactNode {
    return (<div className="not-found-page">
      <Navbar />
      <div className='not-found-contents'>
        <p className='not-found-header'>Redirecting to recommended lamination...</p>
        <br />
        <a className='not-found-link' href="https://www.amazon.com/dp/B00007E7D2?ref=ppx_yo2_dt_b_product_details&th=1&_encoding=UTF8&tag=texttopic-20&linkCode=ur2&linkId=1c03fcc8d2d4154a6ba0aaf858129888&camp=1789&creative=9325">Click here if you are not automatically redirected.</a>
      </div>
      <Footer />
    </div>)
  }
}
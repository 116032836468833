import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/rules.css";




export class Rules extends React.Component<{}, {}> {

  render(): React.ReactNode {
    return (<div className="rules-page">
      <Navbar />
      <div className='rules-contents'>
        <h1>How To Play HEATED</h1>
        <div className='how-to-play-video-container'>
          <iframe className='how-to-play-video' src="https://www.youtube.com/embed/LD68-G5webs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <p className='rules-header'>OR</p>
        <a className='rules-link' href={require('../pdf/manual.pdf')}>Click here to open the PDF of all the HEATED Rules.</a>
      </div>
      <Footer />
    </div>)
  }
}
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/not-found.css";

import lostInSauce from "../images/lost-in-sauce.jpg";


export default class NotFound extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (<div className="not-found-page">
      <Navbar />
     <div className='not-found-contents'>
       <p className='not-found-header'>Uh oh, boyo! You got lost in the sauce!</p>
       <img className='not-found-img' src={lostInSauce} alt="Dizzy Chilis"/>
       <br/>
       <a className='not-found-link' href="/">Click here to go back home!</a>
     </div>
      <Footer/>
    </div>)
  }
}
import React from 'react';
import '../css/navbar.css';

import heatedLogo from "../images/HEATED-nav-logo.png";
import { Link } from 'react-router-dom';

export default class Navbar extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (
      <div className="navbar-container">
        <div className='navbar'>
          <div className='navbar-left'>
            <div className='heated-logo-container'>
              <a href="/"><img src={heatedLogo} alt="Heated Logo" title="Heated Logo"
                height={50} width={131}
                className='heated-logo' /></a>
            </div>
          </div>
          <div className='navbar-right'>
            <div className='navbar-item'>
              <Link className='navbar-link' to="/rules">Rules</Link>
            </div>
            <div className='navbar-item'>
              <a className='navbar-link' href="https://heatedgame.myshopify.com" target="_blank" rel="noopener noreferrer">Order</a>
            </div>
          </div>
        </div>
      </div>)
  }
}
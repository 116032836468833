import React, { Suspense } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/rules-archive.css";

import cardCoolOffBlue from "../images/cards/Card-CoolOff-Blue.jpg";
import cardDraw2Green from "../images/cards/Card-Draw2-Green.jpg";
import cardReverseMagenta from "../images/cards/Card-Reverse-Magenta.jpg";
import cardTooHotYellow from "../images/cards/Card-Too-Hot-Yellow.jpg";
import cardSwapBlue from "../images/cards/Card-Swap-Blue.jpg";

import cardApocalypse from "../images/cards/Card-Apocalypse.jpg";
import cardAdjustSpiceLevel from "../images/cards/Card-ASL.jpg";
import cardDraw4 from "../images/cards/Card-Draw-4.jpg";
import cardGettingHeated from "../images/cards/Card-Getting-Heated.jpg";
import cardGhostPepperSpray from "../images/cards/Card-Ghost-Pepper-Spray.jpg";
import cardNoThanks from "../images/cards/Card-No thanks.png";
import cardFairPlay from "../images/cards/Fair Play.png";

// expansion
import cardGift from "../images/cards/Card-Gift.png";
import cardUnitedWeFall from "../images/cards/Card-United we Fall.png";
import cardGiveAndTake from "../images/cards/Card-Give and Take.png";
import cardSuperSwap from "../images/cards/Card-SuperSwap.png";
import cardReorganize from "../images/cards/Card-Reorganize.png";
import cardTheFinish from "../images/cards/Card-The Finish.png";
import cardCopy from "../images/cards/Card-Copy.png";
import cardMercy from "../images/cards/Card-Mercy.png";
import cardSpiceMix from "../images/cards/Card-Spice mix.png";
import cardTooChili from "../images/cards/Card-Too Chili.png";
import cardTrap from "../images/cards/Card-Trap.png";
import cardTooHotExpansion from "../images/cards/4 TOO HOTS.jpg";
import cardExpansionGhostPepperSpray from "../images/cards/Expansion-Ghost Pepper Spray.png"
import cardBlank from "../images/cards/Blank.png";


type CardListItemProps = {
  boldText: string;
  regularText: string;
}

const CardListItem = (props: CardListItemProps) => {
  return <p className='card-list-item'>
    <span className='card-list-item-bold'>{props.boldText}</span> {!!props.regularText && <span>&ndash; {props.regularText}</span>}
  </p>;
}

type CardExplanationProps = {
  cardTitle: string;
  cardExplanation: string;
  cardImg: string;
  emboldenList: string[];
}

const CardExplanation = (props: CardExplanationProps) => {

  // let cardExplanationText = props.cardExplanation;

  const cardLines = props.cardExplanation.split("\n");
  // let cardExplanationLines = [];
  let bulletedHtml: JSX.Element[] = [];

  for (let q = 0; q < cardLines.length; q++) {

    if (cardLines[q].trim() !== "") {
      if (props.emboldenList.length === 0) {
        bulletedHtml.push(<li key={q} className='card-explanation-bullet' dangerouslySetInnerHTML={{ __html: cardLines[q].trim() }}></li>)
      }
      else {

        let currentLineText = cardLines[q].trim()
        for (let i = 0; i < props.emboldenList.length; i++) {
          const termToEmbolden = props.emboldenList[i];
          const wordToEmboldenSplit = termToEmbolden.split(" ");
          let styledExplanationText = "";
          const splitCardExplanationText = currentLineText.split(" ");
          for (let w = 0; w < splitCardExplanationText.length; w++) {
            const wordsToCheck = splitCardExplanationText.slice(w, w + wordToEmboldenSplit.length).join(" ");
            if (wordsToCheck === termToEmbolden || wordsToCheck === termToEmbolden + "," || wordsToCheck === termToEmbolden + ".") {
              styledExplanationText += "<strong>" + wordsToCheck + "</strong> "
              if (wordToEmboldenSplit.length > 1) {
                w += wordToEmboldenSplit.length - 1
              }
            }
            else {
              styledExplanationText += splitCardExplanationText[w] + " "
            }
          }
          currentLineText = styledExplanationText;
        }
        bulletedHtml.push(<li key={q} className='card-explanation-bullet' dangerouslySetInnerHTML={{ __html: currentLineText }}></li>)
      }

    }
  }


  return <div className='card-explanation-container'>
    <div className='card-explanation-text'>
      <strong>{props.cardTitle} &ndash;</strong>
      <ul>
        {bulletedHtml}
      </ul>
    </div>
    <div className='card-explanation-image-container'>
      <LazyLoadImage className='card-explanation-image'
        alt={props.cardTitle}
        height={250}
        effect="opacity"
        src={props.cardImg}
        width={170} />
    </div>
  </div>
}

const CardExplanationLazy = (props: CardExplanationProps) => {

  return <Suspense fallback={<div>Loading {props.cardTitle}</div>}>
    <CardExplanation cardTitle={props.cardTitle} cardExplanation={props.cardExplanation}
      cardImg={props.cardImg} emboldenList={props.emboldenList} />
  </Suspense>
}


export default class Rules extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (<div className="App">
      <Navbar />
      <div className='rules-container'>
        <div className='rules-header-container'>
          <h1 className='rules-header'>HEATED<br />How To Play</h1>
        </div>

        <div className='table-of-contents-container'>
          <h2 className='rules-section-header table-of-contents-header'>Table of Contents</h2>
          <a className='table-of-contents-section-link' href="#contentsOfDeck">
            <p className='table-of-contents-section-link-text'>Contents of Deck</p>
          </a>
          <a className='table-of-contents-section-link' href="#howToWin">
            <p className='table-of-contents-section-link-text'>How To Win</p>
          </a>
          <a className='table-of-contents-section-link' href="#setup">
            <p className='table-of-contents-section-link-text'>Setup</p>
          </a>
          <a className='table-of-contents-section-link' href="#colorActionCards">
            <p className='table-of-contents-section-link-text'>Color Action Cards</p>
          </a>
          <a className='table-of-contents-section-link' href="#specialCards">
            <p className='table-of-contents-section-link-text'>Special Cards</p>
          </a>
          <a className='table-of-contents-subsection-link' href="#expansionPack">
            <p className='table-of-contents-subsection-link-text'>Expansion Pack</p>
          </a>
          <a className='table-of-contents-section-link' href="#playingTheGame">
            <p className='table-of-contents-section-link-text'>Playing The Game</p>
          </a>
          <a className='table-of-contents-section-link' href="#suggestedHouseRules">
            <p className='table-of-contents-section-link-text'>Suggested House Rules</p>
          </a>
          <a className='table-of-contents-section-link' href="#baseGameMode">
            <p className='table-of-contents-section-link-text'>Base Game Mode</p>
          </a>
          <a className='table-of-contents-section-link' href="#customGameModes">
            <p className='table-of-contents-section-link-text'>Custom Game Modes</p>
          </a>

        </div>

        <div className='contents-of-deck-container' id="contentsOfDeck">
          <h2 className='rules-section-header'>Contents of Deck</h2>
          <p className='card-contents-small-header' id="baseGameHeader">
            <em>
              Base Deck is 134 cards as follows:
            </em>
          </p>

          <CardListItem boldText='20 Blue Cards' regularText='two sets of 0 to 9' />
          <CardListItem boldText='20 Green Cards' regularText='two sets of 0 to 9' />
          <CardListItem boldText='20 Purple Cards' regularText='two sets of 0 to 9' />
          <CardListItem boldText='20 Yellow Cards' regularText='two sets of 0 to 9' />
          <CardListItem boldText='8 COOL OFF cards' regularText='2 each in Blue, Green, Purple and Yellow' />
          <CardListItem boldText='8 REVERSE cards' regularText='2 each in Blue, Green, Purple and Yellow' />
          <CardListItem boldText='8 DRAW 2 cards' regularText='2 each in Blue, Green, Purple and Yellow' />
          <CardListItem boldText='4 TOO HOT cards' regularText='1 each in Blue, Green, Purple and Yellow' />
          <CardListItem boldText='4 SWAP cards' regularText='1 each in Blue, Green, Purple and Yellow' />
          <br />
          <CardListItem boldText='4 ADJUST SPICE LEVEL cards' regularText='' />
          <CardListItem boldText='4 DRAW 4 cards' regularText='' />
          <CardListItem boldText='4 GETTING HEATED cards' regularText='' />
          <CardListItem boldText='4 NO, THANKS cards' regularText='' />
          <CardListItem boldText='1 GHOST PEPPER SPRAY card' regularText='' />
          <CardListItem boldText='2 APOCALYPSE cards' regularText='' />
          <CardListItem boldText='2 FAIR PLAY cards' regularText='' />
          <CardListItem boldText='1 Instruction Manual card' regularText='' />
          <br />
          <p className='card-contents-small-header'>
            <em >
              Expansion Pack is as follows:
            </em>
          </p>

          <CardListItem boldText='2 UNITED WE FALL cards' regularText='' />
          <CardListItem boldText='2 FAIR PLAY cards' regularText='' />
          <CardListItem boldText='2 MERCY cards' regularText='' />
          <CardListItem boldText='2 COPY cards' regularText='' />
          <CardListItem boldText='2 GIVE AND TAKE cards' regularText='' />
          <CardListItem boldText='1 SUPER SWAP card' regularText='' />
          <CardListItem boldText='1 THE FINISH card' regularText='' />
          <CardListItem boldText='1 REORGANIZE card' regularText='' />
          <CardListItem boldText='1 SPICE MIX card' regularText='' />
          <CardListItem boldText='1 TOO CHILI card' regularText='' />
          <CardListItem boldText='1 GHOST PEPPER SPRAY card' regularText='' />
          <CardListItem boldText='4 TOO HOT cards' regularText='' />
          <CardListItem boldText='1 TRAP card' regularText='' />
          <CardListItem boldText='1 BLANK card' regularText='' />

        </div>

        <div className='how-to-win-container' id="howToWin">
          <h2 className='rules-section-header'>How To Win</h2>
          <p className='how-to-win-text'>The first player to clear all the cards from their hand wins!</p>
        </div>

        <div className='setup-container' id="setup">
          <h2 className='rules-section-header'>Setup</h2>
          <p>
            Shuffle the deck and deal each player 7 cards to start. Place the remaining cards facedown in the center to form the DRAW pile.
          </p>
          <p>
            The youngest player begins the round and play continues to the right (counter-clockwise for those who use clocks for directions). If playing more than one game, the winner of the last round begins.
          </p>

          <p>
            Turn over the top card and begin a DISCARD pile. If the card is a SPECIAL card, randomly return it to the deck and flip another card. The round begins when a colored card appears. Ignore all effects of the flipped card.
          </p>

        </div>

        <div className='color-action-cards-container' id="colorActionCards">
          <h2 className='rules-section-header'>Color Action Cards</h2>
          <CardExplanationLazy cardTitle='DRAW 2'
            cardExplanation={`The next player draws 2 cards. They still play their turn. 
                             This card's effect may stack, which means the next player may play another DRAW 2 card on top of the initial DRAW 2 to avoid drawing and make the next player draw 4 cards. Players may continue stacking until no more DRAW 2 cards are played. The player whose turn comes after the last DRAW 2 played must then draw based on the number of the stacked DRAW 2 cards. For example, 3 DRAW 2 cards would mean the next player draws 6 cards. You cannot stack DRAW 2 cards on DRAW 4 cards, nor vice versa. 

                             Playing a DRAW 2 on top of another DRAW 2 after the previous DRAW card(s) have been acted upon does not stack. The count restarts since the previous DRAW cards have already been acted upon. 

                             This can only be played on a matching color or on another DRAW 2 card.

                             If someone plays NO, THANKS in response to being targeted by a stacked DRAW 2, then the entire stack becomes cancelled. If someone stacks another DRAW 2 after the NO, THANKS is played, the draw count would go back to 2 since the previous cards were cancelled already.`}
            cardImg={cardDraw2Green}
            emboldenList={["DRAW 2", "DRAW 4", "NO, THANKS"]} />
          <CardExplanationLazy cardTitle='REVERSE'
            cardExplanation={`Reverse the direction of play. If playing to the right, switch to the left, and vice versa. 
                             This can only be played on a matching color or on another REVERSE card. 
                             This card cannot be stopped with NO, THANKS.
                             In a 2-Player game, REVERSE does NOT skip the other player's turn.`}
            cardImg={cardReverseMagenta}
            emboldenList={["REVERSE", "NO, THANKS"]} />
          <CardExplanationLazy cardTitle='COOL OFF'
            cardExplanation={`The next player must skip their turn. 
                             This can only be played on a matching color or on another COOL OFF card.
                             This card cannot be stopped with NO, THANKS.`}
            cardImg={cardCoolOffBlue}
            emboldenList={["COOL OFF", "NO, THANKS"]} />
          <CardExplanationLazy cardTitle='TOO HOT'
            cardExplanation={`Everyone must slap the table. The last player to slap must draw 2 cards from the DRAW pile. 
                             If there appears to be a tie for last slap, all of the players who last slapped draw 1 card each. Majority rules on who slapped last, with the one who originally played the card acting as the tie breaker if there is a dispute. 
                             This can only be played on a matching color or on another TOO HOT card. 
                             You cannot play this card in the middle of someone's draw phase so that everyone has a reasonable chance to slap.
                             This card cannot be stopped with NO, THANKS.`}
            cardImg={cardTooHotYellow}
            emboldenList={["TOO HOT", "DRAW", "NO, THANKS"]} />
          <CardExplanationLazy cardTitle='SWAP'
            cardExplanation={`Swap hands with a player of your choice. 
                             You may not opt out of this card's effect.
                              If this is your last card, you win without having to swap since you have nothing to give another player. 
                             This can only be played on a matching color or on another SWAP card.
                             `}
            cardImg={cardSwapBlue}
            emboldenList={["SWAP"]} />
        </div>

        <div className='special-cards-container' id="specialCards">
          <h2 className='rules-section-header'>Special Cards</h2>
          <p className='special-cards-explanation'>
            There are two types of <strong>Special</strong> cards: <span className='black-special-card-text'>BLACK</span> and <span className='white-special-card-text'>WHITE.</span> Either can be played after any card type.
          </p>
          <p>
            When <span className='white-special-card-text'>WHITE</span> cards get played, its effect is enacted, then it is moved to the <span className='underline'>bottom</span> of the DISCARD pile because they do not effect what can be played on the next turn. All <span className='black-special-card-text'>BLACK</span> cards change the color of play, but <span className='white-special-card-text'>WHITE</span> cards do not.
          </p>
          <CardExplanationLazy cardTitle='ADJUST SPICE LEVEL'
            cardExplanation={`Change the color being played to any color, even if it is the current color.
                             This card cannot be stopped with NO, THANKS.`}
            cardImg={cardAdjustSpiceLevel}
            emboldenList={["NO, THANKS"]} />
          <CardExplanationLazy cardTitle='DRAW 4'
            cardExplanation={`The next player draws 4 cards. They still play their turn. 
                             This card's effect may stack, which means the next player may play another DRAW 4 card on top of the initial DRAW 4 to make the next player draw 8 cards. Players may continue stacking until no more DRAW 4 cards are played. The player whose turn comes after the last DRAW 4 played must then draw based on the number of the stacked DRAW 4 cards. For example, 3 DRAW 4 cards would mean the next player draws 12 cards. You cannot stack DRAW 4 cards on DRAW 2 cards, nor vice versa.
                             Change the color being played to any color, even if it is the current color.
                             If someone plays NO, THANKS in response to being targeted by a stacked DRAW 4, then the entire stack becomes cancelled. If someone stacks another DRAW 4 after the NO, THANKS is played, the draw count would go back to 4 since the previous cards were cancelled already.
                             `}
            cardImg={cardDraw4}
            emboldenList={["DRAW 4", "DRAW 2", "NO, THANKS"]} />
          <CardExplanationLazy cardTitle='GHOST PEPPER SPRAY'
            cardExplanation={`Number off all other players in any order you would like, starting from 1 and increasing by increments of 1. The number each player is given is the number of cards they must draw. Players will draw in the order of their numbering.

                             For example, if you are playing with 5 players, the one playing the card would number the remaining players 1-4 in any order they would like. The person numbered “1” would draw 1 card first. The player numbered “2” would draw 2 cards second. This continues until all have drawn. 

                             Change the color being played to any color, even if it is the current color.`}
            cardImg={cardGhostPepperSpray}
            emboldenList={[]} />
          <CardExplanationLazy cardTitle='NO, THANKS'
            cardExplanation={`Play this card, then discard to the <span class='underline'>bottom</span> of the DISCARD pile. Skip your turn or cancel the effect of any SWAP, DRAW 2, or Special card played against you, on only yourself. 

                             For example, playing NO, THANKS against a GHOST PEPPER SPRAY card would mean that you no longer must draw, but everyone else would still have to draw the number they are assigned. You may choose to play NO, THANKS before or after the one who plays GHOST PEPPER SPRAY assigns numbers to everyone.

                             You cannot NO, THANKS someone else's NO, THANKS.
                             `}
            cardImg={cardNoThanks}
            emboldenList={["Special", "NO, THANKS", "GHOST PEPPER SPRAY", "DRAW 2", "DRAW", "SWAP"]} />
          <CardExplanationLazy cardTitle='GETTING HEATED'
            cardExplanation={`Force <em>any</em> player to draw 2 cards. 
                             This card’s effect may stack, which means one may play another GETTING HEATED card on top of the initial GETTING HEATED to target a new (or the same) player and force them to draw 4 cards. Players may continue stacking until no more GETTING HEATED cards are played. The last targeted player must then draw based on the number of stacked GETTING HEATED cards. For example, 3 GETTING HEATED cards would mean the last player to be targeted draws 6 cards. 
                             If someone plays NO, THANKS in response to being targeted by a stacked GETTING HEATED, then the entire stack becomes canceled. If someone stacks another GETTING HEATED after the NO, THANKS, the draw count would go back to 2 since the previous cards were canceled already.
                             Change the color being played to any color, even if it is the current color.
                             `}
            cardImg={cardGettingHeated}
            emboldenList={["GETTING HEATED", "NO, THANKS"]} />


          <CardExplanationLazy cardTitle='FAIR PLAY'
            cardExplanation={`Choose a player. The one with more cards between you two discards to the bottom of the DISCARD pile until both players have the same number of cards in hand. 
                             If both players already have the same number of cards, nothing happens. 
                             For example, if you have ten cards and the player you choose has two cards, you may discard until you have two cards remaining. Likewise, if you have two cards and the player you choose has ten cards, <em>they</em> may discard until they are down to two cards. 

                             Change the color being played to any color, even if it is the current color. You may choose to announce the color that continues play before or after the player has discarded.`}
            cardImg={cardFairPlay}
            emboldenList={[]} />


          <CardExplanationLazy cardTitle='APOCALYPSE'
            cardExplanation={`Choose a color to continue play, even if it is the current color. Everyone BUT you must discard 1 card of that color to the <span class="underline">bottom</span> of the DISCARD pile. If they do not have a card to discard, they must draw 1 card. 

                             Players draw in their turn order. 

                             In the rare instance when two players discard their final card at the same time, the first one to have their card <em>touch</em> the DISCARD pile wins.`}
            cardImg={cardApocalypse}
            emboldenList={[]} />
        </div>

        <div className='expansion-pack-container' id="expansionPack">
          <h2 className='rules-section-header'>Expansion Pack</h2>
          <p>This Expansion Pack adds 22 more cards, each with a special gold mini-flame to help distinguish them from the main deck. All <strong>BLACK</strong> cards can be canceled by <strong>NO, THANKS</strong> unless otherwise stated. It also adds a <strong>BLANK</strong> card so that you can customize your own card or replace a lost card.
          </p>
          <p>
            The game was designed to function without these cards, so one may choose to add any or none of them to their deck. See CUSTOM GAME MODES below for suggestions.
          </p>



          <CardExplanationLazy cardTitle='UNITED WE FALL'
            cardExplanation={`Everyone, including yourself, must draw 2 cards. 

                             If this is your last card, the game ends without you having to draw.

                             Players draw in their turn order. 

                             Change the color being played to any color, even if it is the current color.
                             `}
            cardImg={cardUnitedWeFall}
            emboldenList={[]} />


          <CardExplanationLazy cardTitle='GIFT'
            cardExplanation={`Everyone BUT YOU must draw 1 card. 
                             Change the color being played to any color, even if it is the current color. 
                             Players draw in their turn order.
                             `}
            cardImg={cardGift}
            emboldenList={[]} />


          <CardExplanationLazy cardTitle='GIVE AND TAKE'
            cardExplanation={`Switch one card of your choosing from your hand with a randomly selected card of another player. 

                             This card <strong>cannot</strong> be stopped with NO, THANKS.

                             Change the color being played to any color, even if it is the current color. 
                             `}
            cardImg={cardGiveAndTake}
            emboldenList={["NO, THANKS"]} />


          <CardExplanationLazy cardTitle='SUPER SWAP'
            cardExplanation={`Draw 2 cards then swap hands with a player of your choice.
                             
                             You may not opt out of this card's effect.
                             
                             If this is your last card, you win without having to swap since the game ended as soon as the card touched the DISCARD pile. 

                             Change the color being played to any color, even if it is the current color. You may choose to announce the color that continues play before or after swapping hands.`}
            cardImg={cardSuperSwap}
            emboldenList={[]} />

          <CardExplanationLazy cardTitle='REORGANIZE'
            cardExplanation={`Change the playing position of another player. Choose a player and have them physically move to a new location in the playing order. 

                             Change the color being played to any color, even if it is the current color.`}
            cardImg={cardReorganize}
            emboldenList={[]} />

          <CardExplanationLazy cardTitle='THE FINISH'
            cardExplanation={`Everyone must discard cards to the <span class='underline'>bottom</span> of the DISCARD pile until only 2 cards remain in their hand.
                             
                             If someone already has 1 or 2 cards, they are unaffected, which means they cannot play a NO, THANKS.  

                             Change the color being played to any color, even if it is the current color. You may choose to announce the color that continues play before or after the other players have discarded.`}
            cardImg={cardTheFinish}
            emboldenList={["NO, THANKS"]} />

          <CardExplanationLazy cardTitle='COPY'
            cardExplanation={`This card can only be played on your turn. Play this card, then discard to the <span class='underline'>bottom</span> of the DISCARD pile. It acts exactly like the top card of the DISCARD pile. 

                             For example, if the top card was a GREEN 7, this card would be a GREEN 7. If the top card was a DRAW 4, this card now acts as a DRAW 4. 
                             
                             COPY cannot act like another <span class="white-special-card-text">WHITE</span> card since those always go to the bottom of the DISCARD pile.`}
            cardImg={cardCopy}
            emboldenList={["COPY", "DRAW 4"]} />

          <CardExplanationLazy cardTitle='MERCY'
            cardExplanation={`Play this card, then discard to the <span class='underline'>bottom</span> of the DISCARD pile and end your draw phase. If it was your turn, playing MERCY also ends your turn. 

                             For example, if you are made to draw 4 cards and the first card you draw is a MERCY card, you can stop drawing by discarding this card. Likewise, if MERCY is already in your hand and you are made to draw cards, playing MERCY allows you to stop the draw phase and end your turn. Likewise, if after a DRAW 4, the 4th card you draw is MERCY, you still discard it, ending your draw phase with only 3 new cards in hand.
                             
                             If someone forces you draw cards out of turn, with GETTING HEATED for example, then playing MERCY from your hand would also end your draw phase. Playing MERCY out of turn does <span class='underline'>not</span> change the turn order. The game would continue from whoever played the last card, which in this example is GETTING HEATED.
                             
                             If on your turn, you are holding MERCY and have no other cards to play, you can play it to end your draw phase and your turn. If you were drawing because you had no card to play, drawing and discarding MERCY would allow you to end your turn without playing anything on top of the DISCARD pile.`}
            cardImg={cardMercy}
            emboldenList={["MERCY", "GETTING HEATED"]} />

          <CardExplanationLazy cardTitle='SPICE MIX'
            cardExplanation={`Collect everyone's cards and redistribute them, one-by-one in the direction of play, until all cards are passed out. 
                             
                             If playing with more than 5 players, it's highly recommended to remove this card from play. SPICE MIX can cause a game to go on much longer than intended!

                             Change the color being played to any color, even if it is the current color. You may choose to announce the color that continues play before or after the cards have been redistributed.`}
            cardImg={cardSpiceMix}
            emboldenList={["SPICE MIX"]} />

          <CardExplanationLazy cardTitle='TOO CHILI'
            cardExplanation={`The one who plays this card must slap the table. Anyone else who slaps the table must draw 1 card. 
                             Change the color being played to any color, even if it is the current color.
                             This card cannot be stopped with NO, THANKS.`}
            cardImg={cardTooChili}
            emboldenList={["NO, THANKS"]} />

          <CardExplanationLazy cardTitle='TRAP'
            cardExplanation={`When this card is played, it may be placed anywhere into the DRAW pile, but face-up. The next player to draw this card must immediately discard it to the <span class='underline'>bottom</span> of the DISCARD pile and draw 2 cards. 
                             
                             If you draw this card face-up in the middle of a draw phase, add 2 to the total number you were supposed to draw. For example, if you are made to draw 2 cards, and draw TRAP as your first card, then you'll need to still draw your second card in addition to 2 more after that. In this example, you should end with 3 new cards in your hand since TRAP is immediately discarded. 

                             If it's your turn, you may still play a card despite having already placed TRAP into the DISCARD pile.`}
            cardImg={cardTrap}
            emboldenList={["TRAP"]} />

          <CardExplanationLazy cardTitle='TOO HOT'
            cardExplanation={`Another 4 TOO HOT cards to add to the deck! It acts just like a regular TOO HOT card. 
            To avoid confusion on who was last to slap, you MUST allow everyone to complete their draw phase before playing another TOO HOT atop any card, especially another TOO HOT when trying to steal a turn.`}
            cardImg={cardTooHotExpansion}
            emboldenList={["TOO HOT"]} />

          <CardExplanationLazy cardTitle='GHOST PEPPER SPRAY'
            cardExplanation={`Another GHOST PEPPER SPRAY card to add to your deck! It acts just like the other one.
                             To avoid a situation where you run out of cards to draw, it’s recommended not to add this extra GHOST PEPPER SPRAY card to the deck if playing with more than 6 players.
                             `}
            cardImg={cardExpansionGhostPepperSpray}
            emboldenList={["GHOST PEPPER SPRAY"]} />

          <CardExplanationLazy cardTitle='BLANK CARD'
            cardExplanation={`Write your own rules or use it to replace a lost card.
                             `}
            cardImg={cardBlank}
            emboldenList={[]} />


        </div>

        <div className='playing-the-game-container' id="playingTheGame">
          <h2 className='rules-section-header'>Playing The Game</h2>
          <ol>
            {/* 1. */}
            <li>
              <p>On your turn, play a card that matches the color or image of the card on top of the DISCARD pile. For example, if a BLUE <strong>REVERSE</strong> was the last card played, then you may play any BLUE card or a <strong>REVERSE</strong> card of any color. Alternatively, you may play a <strong>Special</strong> card, even if you have a playable BLUE or <strong>REVERSE</strong> card.
              </p>

              <p><strong>What if you do not have a card to play?</strong></p>
              <p className='playing-the-game-you-have-two-options'>
                You have two options.
              </p>
              <p className='playing-the-game-subtitle-indent'>
                <strong>a)</strong>	Draw from the DRAW pile until you get a card you may play. Immediately play the card.
              </p>
              <p className='playing-the-game-subtitle-indent'>
                <strong>b)</strong>	Ask for help. You cannot ask for help if you have already begun drawing from the DRAW pile. The other players may offer you a single card, facedown. You may only choose one card to take. If it is playable, you must immediately play it. If they were deceiving you and offered you an unplayable card, you must now draw until you get a playable card. You cannot ask for help again until your next turn.
              </p>
              <p>You <strong>MAT NOT</strong> draw or ask for help if you already have a playable card in hand. </p>
            </li>
            {/* 2. */}
            <li>
              <p>If the DRAW pile runs out, leave the top card of the DISCARD pile, and shuffle the rest of the cards. Place the shuffled deck facedown as the new DRAW pile.
              </p>

            </li>
            {/* 3. */}
            <li>
              <p>You may play out of turn if you have the <span className='underline'>EXACT SAME CARD</span> (same color and image) as the top card of the DISCARD pile. For example, if someone plays a GREEN 3, you may play a <span className='underline'>GREEN</span> 3 immediately on top of it, even if it is not your turn. The play now continues with the player after you. This can be especially useful for stacking <strong>DRAW 2, DRAW 4</strong> or <strong>GETTING HEATED</strong> cards out of turn. If you have two of the same cards in your hand, you can play them back-to-back. They must be played separately though. You cannot drop them down together at the same time.
              </p>

            </li>
            {/* 4. */}
            <li>
              <p>When placing your second-to-last card on the DISCARD pile, you must say, “HEATED!” the moment your card touches the pile.
              </p>
              <p className='playing-the-game-subtitle-indent'>
                <strong>a)</strong>	If someone says it before you, you must draw 2 cards. In the case of a tie between two people saying it, the advantage goes to the one who just played their card down.
              </p>
              <p className='playing-the-game-subtitle-indent'>
                <strong>b)</strong>	If you swap hands with someone who only has 1 card left, you do NOT have to say “HEATED” when receiving their one card.
              </p>
              <p className='playing-the-game-subtitle-indent'>
                <strong>c)</strong>	If everyone forgets to call “HEATED!”, the chance to incur the penalty goes away as soon as the next player draws a card from the DRAW pile or their hand to play, or they ask for help.
              </p>

              <p>
                If someone's second-to-last card is a <strong>SWAP, SUPER SWAP, THE FINISH</strong> or <strong>FAIR PLAY</strong> and they are beaten to saying “HEATED,” then they will first fulfill the cards effect, and then draw the 2-card penalty.
              </p>

            </li>
            {/* 5. */}
            <li>
              <p>The game ends when someone plays their last card &#8211; or sneakily gives away their last card to someone asking for help.</p>
            </li>
          </ol>
          <p>
            <strong>IMPORTANT NOTES</strong>
          </p>
          <ul>
            <li>
              <p> If a card forces you to discard, always discard to the <span className='underline'>bottom</span> of the DISCARD pile so you do not effect the next card that's being played.  </p>
            </li>
            <li>
              <p>
                You are not allowed to hide your cards such that others do not know your card count.
              </p>
            </li>
            <li>
              <p>
                <span className='white-special-card-text'>WHITE</span> cards, when played out of turn, do not effect the turn order. This applies to <strong>NO, THANKS</strong> being played in reaction to another card, <strong>TRAP</strong> when drawn face-up, and <strong>MERCY</strong> being drawn during an out-of-turn draw phase.
              </p>

            </li>
          </ul>
        </div>

        <div className='suggested-house-rules-container' id="suggestedHouseRules">
          <h2 className='rules-section-header'>Suggested House Rules</h2>
          <p>
            These are <strong>NOT</strong> part of the official rules, <em>but</em> they provide a way to make the game a little more exciting if one is looking to spice up their regular play.
          </p>
          <ol className='suggested-house-rules-list'>
            <li className='suggested-house-rules-list-item'>If someone asks, “Whose turn is it?” they draw 1 card.</li>
            <li className='suggested-house-rules-list-item'>If someone yells “HEATED!” without there being a reason to, they draw 1 card. </li>
            <li className='suggested-house-rules-list-item'>If someone slaps the table without there being a reason to, they draw 1 card. </li>
            <li className='suggested-house-rules-list-item'><strong>UNITED WE FALL</strong> cannot be your last card played. You must draw 2 cards and continue play. </li>
            <li className='suggested-house-rules-list-item'><strong>SUPER SWAP</strong> cannot be your last card played. You must draw 2 cards, swap, and continue play.</li>
            <li className='suggested-house-rules-list-item'>When someone plays a <strong>DRAW 2</strong> or <strong>DRAW 4</strong> card on you, and you don't have an appropriate <strong>DRAW</strong> card to stack it with, you can ask for help even if you have cards you could play after your draw phase. For example, if someone plays a GREEN <strong>DRAW 2</strong> on you, you can ask others for a <strong>DRAW 2</strong> to stack on top so that you pass on the (now stacked) draw phase to the next player <em>even</em> if you have a GREEN card in hand. </li>
            {/* 6. */}
            <li className='suggested-house-rules-list-item'>
              Change <strong>REORGANIZE</strong> so that two players switch places, instead of moving a single player.
            </li>

          </ol>
        </div>

        <div className='expansion-pack-container' id="baseGameMode">
          <h2 className='rules-section-header'>Base Game Mode</h2>
          <p>This is how the game was meant to be played. Simply use <a className='base-game-mode-link-to-cards' href="#baseGameHeader">all the cards <em>not</em> included in the EXPANSION PACK.</a> This means playing with cards that do NOT have a golden mini-flame.</p>

          <p>For suggestions on how to best utilize the EXPANSION PACK cards, please see Custom Game Modes below.</p>


        </div>

        <div className='custom-game-modes-container' id="customGameModes">
          <h2 className='rules-section-header'>Custom Game Modes </h2>
          <p>With all the added Special cards from the Expansion Pack, it can seem overwhelming to add them all into the game at once. Below are some suggested setups to cater your game to the type of players you are playing with. </p>

          <p>Custom game modes coming soon!</p>



          {/* <ol>
              <li>
                <p>
                  <strong>The Classic</strong> &ndash; simplest way to play if you want a game that has the least number of rules and the least complication. 
                </p>
                </li>
            </ol> */}

        </div>


      </div>

      <Footer />
    </div>)
  }
}
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Home";
import RulesArchive from "./RulesArchive";
import NotFound from "./NotFound";
import LaminateRedirect from "./LaminateRedirect";
import DeckBoxRedirect from "./DeckBoxRedirect";
import { Rules } from "./Rules";
import PrintAtHomeRedirect from "./PrintAtHomeRedirect";
import { LowInkRedirect } from "./LowInkRedirect";

import '../css/app.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route key="home" path="/" element={<Home />} />
        <Route key="rules" path="/rules" element={<Rules />} />
        <Route key="low-ink" path="/low-ink" element={<LowInkRedirect />} />
        <Route key="print-at-home" path="/print-at-home" element={<PrintAtHomeRedirect />} />
        <Route key="rules-archive" path="/rules-archive" element={<RulesArchive />} />
        <Route key="laminate" path="/laminate" element={<LaminateRedirect />} />
        <Route key="deck-box" path="/deck-box" element={<DeckBoxRedirect />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

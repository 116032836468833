import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "../css/not-found.css";




export default class PrintAtHomeRedirect extends React.Component<{}, {}> {

  componentDidMount() {
    window.location.href = require('../pdf/print-at-home.pdf');
  }

  render(): React.ReactNode {
    return (<div className="not-found-page">
      <Navbar />
      <div className='not-found-contents'>
        <p className='not-found-header'>Redirecting to HEATED - Print At Home Edition...</p>
        <br />
        <a className='not-found-link' href={require('../pdf/print-at-home.pdf')}>Click here if you are not automatically redirected.</a>
      </div>
      <Footer />
    </div>)
  }
}
import React from 'react';
import '../css/footer.css';

export default class Footer extends React.Component<{}, {}> {


  render(): React.ReactNode {
    const initialYear = 2022;
    const currentYear = new Date().getFullYear();

    const thisYear: string = initialYear !== currentYear ? "2022 - " + currentYear.toString() : "2022";
    return (
      <div className="footer-container">
        <div className='footer'>
          &copy; HEATED Games LLC {thisYear}
        </div>
      </div>)
  }
}